'use client'

import { IRace } from '@/types'
import { Box, Button } from '@mui/material'
import { useRouter } from 'next/navigation'
import { isRegistrationOpen } from '@/util/race'

export default function RegistLink({ race }: { race: IRace }) {
  const router = useRouter()

  const handleClick = (e) => {
    e.preventDefault()
    router.push(`/kilpailu/${race.id}/ilmoittaudu`)
  }

  if (isRegistrationOpen(race)) {
  }

  return (
    <Box>
      {isRegistrationOpen(race) ? (
        <Button size="small" onClick={(e) => handleClick(e)}>
          Ilmoittaudu
        </Button>
      ) : null}
    </Box>
  )
}
