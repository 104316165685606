'use client'

import * as React from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Typography } from '@mui/material'
import { useRouter } from 'next/navigation'

type SelectYearProps = {
  basePath: string
  selectedYear: string
  availableYears: string[]
}

const SelectYear = ({ basePath, selectedYear, availableYears }: SelectYearProps) => {
  const router = useRouter()
  const value = selectedYear

  const handleSelect = (e: SelectChangeEvent<string>) => {
    const year = e.target.value as string
    router.push(`${basePath}${year}`)
  }

  return (
    <Box display="flex">
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body2">Näytä vuosi</Typography>
      </Box>

      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select value={value} displayEmpty size="small" onChange={(event) => handleSelect(event)}>
          {availableYears.map((year) => (
            <MenuItem key={year} value={year}>
              <Typography variant="body2" color="textPrimary">
                {year}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default SelectYear
