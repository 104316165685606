'use client'

import * as React from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Typography } from '@mui/material'
import { ISportItem } from '@/types'
import slugify from 'slugify'
import { useRouter } from 'next/navigation'

type SelectSportProps = {
  basePath: string
  selectedSport: ISportItem | null
  availableSports: ISportItem[]
}

const SelectSport = ({ basePath, selectedSport, availableSports }: SelectSportProps) => {
  const router = useRouter()
  const value = selectedSport ? slugify(selectedSport.name).toLowerCase() : 'all'

  const handleSelect = (e: SelectChangeEvent<string>) => {
    const sport = e.target.value === 'all' ? '' : e.target.value
    router.push(`${basePath}${sport}`)
  }

  return (
    <Box display="flex">
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body2">Näytä laji</Typography>
      </Box>

      <FormControl size="small" sx={{ m: 1, minWidth: 120 }}>
        <Select value={value} displayEmpty size="small" onChange={handleSelect}>
          <MenuItem key="all" value="all">
            <Typography fontStyle="italic" color="textPrimary">
              Kaikki
            </Typography>
          </MenuItem>
          {availableSports.map((sport) => (
            <MenuItem key={sport.id} value={slugify(sport.name).toLowerCase()}>
              <Typography color="textPrimary">{sport.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default SelectSport
