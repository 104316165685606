import {
  IRace,
  IRaceExtended,
  IRaceRegistrationExtended,
  IRaceRound,
  IRaceStart,
  IRaceStartExtended
} from '@/types'
import dayjs from 'dayjs'
import slugify from 'slugify'

const extendedRaceFields = [
  'type',
  'hasPublicRounds',
  'hasPublicResults',
  'timestmp',
  'typeName',
  'participantsCount',
  'hasPublicRounds',
  'hasPublicResults',
  'sport',
  'starts',
  'rounds'
]

const extendedStartFields = [
  'serieName',
  'ageGroupName',
  'registrations',
  'participantsMin',
  'participantsMax',
  'gender'
]

export const removeExtendedRaceFields = (race: IRace, logging: boolean = false) => {
  let update = false
  for (const field of extendedRaceFields) {
    if (race[field]) {
      delete race[field]
      if (logging) {
        console.log(`remove field`, field, race.id)
      }
      update = true
    }
  }
  return update
}

export const removeExtendedStartFields = (start: IRaceStart, logging: boolean = false) => {
  let update = false
  for (const field of extendedStartFields) {
    if (start[field]) {
      delete start[field]
      if (logging) {
        console.log(`remove field`, field, start.id)
      }
      update = true
    }
  }
  return update
}

export const convertExtendedRaceToNormal = (race: IRaceExtended): IRace => {
  removeExtendedRaceFields(race, false)
  return race as IRace
}

export const convertExtendedStartToNormal = (start: IRaceStartExtended): IRaceStart => {
  removeExtendedStartFields(start, true)
  return start as IRaceStart
}

export const getRaceYear = (race: IRace) => dayjs(race.dates[0]).year()

export const getStartAnchorId = (start: IRaceStartExtended) => {
  return slugify(`${start.distance}_${start.serieName}_${start.ageGroupName}`)
}

export const getRoundAnchorId = (round: IRaceRound) => {
  return slugify(`${round.name}`)
}

export const isRegistrationOpen = (race: IRace) =>
  dayjs().isAfter(dayjs(race.registrationStartDate)) &&
  dayjs().isBefore(dayjs(race.registrationEndDate))

export const getStartPrice = (start: IRaceStartExtended) => {
  if (start.price === 0) {
    return ''
  } else if (start.pricePerUser) {
    return `${start.price}€ / kilpailija`
  }
  return `${start.price}€`
}

export const getRegistrationPrice = (registration: IRaceRegistrationExtended) => {
  const price =
    (registration.pricePerUser
      ? registration.price * registration.participants.length
      : registration.price) *
    (1 - (registration.discountPercent || 0) / 100)
  return Math.round((price + Number.EPSILON) * 100) / 100
}

export const getRegistrationsTotalPrice = (registrations: IRaceRegistrationExtended[]) => {
  const totalPrice = registrations.reduce(
    (sum, registration) => sum + getRegistrationPrice(registration),
    0
  )
  return totalPrice
}
